define(['app', 'ePopup'], function(app, popup) {
  
  class manageSubscriptions {

    constructor() {
      this.bindEvents();
    }

    bindEvents() {
      const subsManage = document.querySelector('[data-managesubscription]');

      if (subsManage) {
        app.element.on('click', e => this.clickHandler(e, 'manage'), subsManage);
      }

      this.bindCancelEvents();
      this.bindCloseEvents();
      this.bindCheckEvents();
    }

    clickHandler(event, data) {

      switch (data) {
        case 'manage':
          this.showPopup('.subscriptionManagePopup', 'subscriptionManagePopup-active');
          this.bindCancelEvents();
          this.bindCloseEvents();
          break;

        case 'cancel':
          this.showPopup('.subscriptionCancelPopup', 'subscriptionCancelPopup-active');
          this.bindCheckEvents();
          this.bindCloseEvents();
          break;

        case 'close':
          this.closePopup();
          break;

        case 'check':
          this.checkCheckBox();
          this.enableButton();
          this.enableInput();
          break;

        case 'confirm':
          this.submitForm();
          break;
      }
    }

    bindCancelEvents() {
      const subsCancel = document.querySelectorAll('[data-cancelsubscription]');

      if (subsCancel) {
        app.element.on('click', e => this.clickHandler(e, 'cancel'), subsCancel);
      }
    }

    bindCloseEvents() {
      const closeBtn = document.querySelectorAll('[data-closepopup]');

      if (closeBtn) {
        app.element.on('click', e => this.clickHandler(e, 'close'), closeBtn);
      }
    }

    bindCheckEvents() {
      const subsCheckbox = document.querySelectorAll('[data-subcheckbox]');
      const subInput = document.querySelectorAll('[data-cancelInput]');

      if (subsCheckbox) {
        app.element.on('click', e => this.clickHandler(e, 'check'), subsCheckbox);
      }

      if (subInput) {
        app.element.on('click', e => this.clickHandler(e, 'confirm'), subInput);
      }

    }

    cancelFailed() {
      const errorAlert = document.querySelector('.subscriptionDetailsAlertError');
      if (errorAlert) {
        app.element.addClass('active', errorAlert);
      }
      this.closePopup();
    }

    cancelSuccess() {
      this.showPopup('.cancelCompletePopup', 'cancelCompletePopup-active');
      this.bindCloseEvents();
      const subsManage = document.querySelector('[data-managesubscription]');
      const successAlert = document.querySelector('.subscriptionDetailsAlertSuccess');

      if (subsManage) {
        app.element.addClass('hide', subsManage);
      }

      if (successAlert) {
        app.element.addClass('active', successAlert);
      }

    }

    checkCheckBox() {
      const checkbox = document.querySelectorAll('[data-subcheckbox]');

      for (var i = 0; i < checkbox.length; i++) {
        checkbox[i].setAttribute('checked', 'checked');
      }

    }

    closePopup() {
      const closeBtn = document.querySelector('.js-popup-close-btn');

      if (closeBtn) {
        closeBtn.click();
      }

    }

    enableButton() {
      const subLabel = document.querySelectorAll('[data-cancellabel]');

      for (var i = 0; i < subLabel.length; i++) {
        app.element.removeClass('disabled', subLabel[i]);
      }

    }

    enableInput() {
      const subInput = document.querySelectorAll('[data-cancelInput]');

      for (var i = 0; i < subInput.length; i++) {
        subInput[i].removeAttribute('disabled');
      }

    }

    showPopup(subPopup, activeClass) {
      const activePopup = document.querySelector(subPopup);

      if (activePopup) {
        new popup(activePopup, activeClass, true, true);
      }

    }

    submitForm() {
      const form = document.getElementById('manageSubscription');
      let formData = '';

      for (var i = 0; i < form.elements.length; i++) {
        formData += form.elements[i].name + '=' + form.elements[i].value + '&';
      }

      formData = formData.slice(0, -1);

      if (form) {
        const endpoint = form.getAttribute('action');
        app.ajax.post({
          url: endpoint,
          send: formData,
          requestHeader: {
            header: 'Content-Type',
            value: 'application/x-www-form-urlencoded'
          },
          success: () => this.cancelSuccess(),
          error: () => this.cancelFailed()
        });
      }

    }

  }

  return new manageSubscriptions();
});

